document.addEventListener('DOMContentLoaded', () => {
    const isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
    const currentTheme = localStorage.getItem('darkMode') ? localStorage.getItem('darkMode') : (isDarkMode ? 'dark' : 'light');
    document.documentElement.setAttribute('data-bs-theme', currentTheme);

    const toggleDarkMode = () => {
        const theme = document.documentElement.getAttribute('data-bs-theme') === 'dark' ? 'light' : 'dark';
        document.documentElement.setAttribute('data-bs-theme', theme);
        localStorage.setItem('darkMode', theme);
        updateIcons(theme);
    };

    const updateIcons = (theme) => {
        const iconClassToAdd = theme === 'dark' ? 'fa-sun' : 'fa-moon';
        const iconClassToRemove = theme === 'dark' ? 'fa-moon' : 'fa-sun';
        document.querySelectorAll('.darkModeIcon').forEach(icon => {
            icon.classList.remove(iconClassToRemove);
            icon.classList.add(iconClassToAdd);
        });
    };

    updateIcons(currentTheme);

    if (localStorage.getItem('darkMode') === 'dark') {
        document.documentElement.setAttribute('data-bs-theme', 'dark');
    }

    document.querySelectorAll('.darkModeToggle').forEach(button => {
        button.addEventListener('click', toggleDarkMode);
    });
});
